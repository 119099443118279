/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import Logo from "../../static/img/ignis-logo-weiß.svg";

const Navbar = () => {
	return (
		<header
			id="Start"
			sx={{
				display: "flex",
				bg: "transparent",
				width: "100%",
				justifyContent: "center",
				position: "absolute",
				zIndex: 2,
				pt: 1,
				px: [1, 4, 4],
				pb: 0,
			}}
		>
			<div
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					margin: "0 auto",
				}}
			>
				<div
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						maxWidth: "1000px",
					}}
				>
					<Link to="/#Start" sx={{ mr: 3 }}>
						<div sx={{ width: "100%" }}>
							<Logo
								sx={{
									width: ["58%", "80%", "80%"],
									height: "100%",
									cursor: "pointer",
								}}
								title="Agentur ignis Logo"
								alt="Agentur ignis Logo"
							></Logo>
						</div>
					</Link>
				</div>
			</div>
		</header>
	);
};

export default Navbar;

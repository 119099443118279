/** @jsx jsx */
import { jsx } from "theme-ui";

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const CookieConsent = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!getCookieValue()) {
            setVisible(true);
        }
    }, [])

    const accept = () => {
        document.cookie = "cookie_consent=yes; max-age=31536000; path=/";
        window.dataLayer.push({ "event": "page_change" })
        setVisible(false);
    }

    const decline = () => {
        document.cookie = "cookie_consent=no; max-age=31536000; path=/";
        setVisible(false);
    }

    const getCookieValue = () => {
        let nameEQ = "cookie_consent=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    if (!visible) {
        return null;
    }

    return (
        <React.Fragment>
            <section sx={{ position: "fixed", bottom: 0, width: "100%", bg: "#000000", display: "flex", justifyContent: "space-between", alignItems: "center", py: 1, px: 2 }}>
                <div>Wir verwenden Cookies zur Darstellung und Optimierung unserer Website. <Link sx={{ color: "text" }} to="/datenschutz">Datenschutzerklärung</Link></div>
                <div sx={{ display: "flex", flexDirection: ["column-reverse", "column-reverse", "row"], ml: 1 }}>
                    <button onClick={decline} sx={{ px: 2, py: 1, border: "none", bg: "#000000", color: "text", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}>
                        Ablehnen
                    </button>
                    <button onClick={accept} sx={{ px: 2, py: 1, border: "none", cursor: "pointer", bg: "one", color: "text", "&:hover": { textDecoration: "underline" } }}>
                        Akzeptieren
                    </button>
                </div>
            </section>
        </React.Fragment>
    );
}

export default CookieConsent;
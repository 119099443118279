/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import Form from "../components/Form";

export default () => {
  return (
    <Layout>
      <section
        sx={{
          px: 4,
          pt: 7,
        }}
      >
        <div sx={{ maxWidth: "45rem", margin: "0 auto", mb: 6 }}>
          <h1
            sx={{
              variant: "hs.h1",
			  textAlign: "center",
			  mb: 3,
            }}
          >
            Jetzt Termin vereinbaren!
          </h1>
          <h2
            sx={{
              variant: "hs.h2",
              px: [2, "0px", "0px"],
              fontWeight: "semibold",
              textAlign: "center",
              mt: "0px",
              mb: 2,
            }}
          >
            In unserem unverbindlichen Erstgespräch:
          </h2>
          <ol
            sx={{
              pl: 4,
              pr: 2,
              mt: "0px",
              mb: 3,
              li: {
                fontSize: [2, 3, 3],
              },
            }}
          >
            <li>
              Wieso Ihr Onlineauftritt wichtiger als je zuvor ist und was das
              für Ihr Unternehmen konkret bedeutet.
            </li>
            <li>Welche Webstrategie zu Ihren Zielen passt.</li>
            <li>
              Das verborgene Potential Ihrer Webseite und wie sie es zukünftig
              nutzen.
            </li>
            <li>
              Der Weg zum wirksamen Internetauftritt, Schritt für Schritt.
            </li>
          </ol>
          <Form />
        </div>
      </section>
    </Layout>
  );
};

/** @jsx jsx */
import { jsx } from "theme-ui";
import Logo from "../../static/img/ignis-logo-weiß.svg";

const Footer = () => {
  return (
    <footer
      sx={{
        display: "flex",
        justifyContent: "center",
        px: 4,
        py: 4,
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo
          title="Agentur ignis Logo"
          alt="Agentur ignis Logo"
        />
        <div
          sx={{
            pt: 2,
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <a
            sx={{
              variant: "text.body",
              textAlign: "center",
              textDecoration: "none",
              color: "text",
              cursor: "pointer",
            }}
            href="/impressum"
          >
            Impressum
          </a>
          <a
            sx={{
              variant: "text.body",
              textAlign: "center",
              textDecoration: "none",
              color: "text",
              cursor: "pointer",
            }}
            href="/datenschutz"
          >
            Datenschutz
          </a>
        </div>
        <div sx={{mt:1}}>
          <span
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: 1,
              textDecoration: "none",
            }}
          >
            2023 © Agentur ignis
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";

import CookieConsent from "./CookieConsent"

const TemplateWrapper = ({ children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <div>{children}</div>
      <Footer />
      <CookieConsent />
    </React.Fragment>
  );
};

export default TemplateWrapper;
